<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <div class="text-center">
            <v-dialog
                    v-model="dialog"
                    persistent
                    width="50"
            >
                <img src='../../assets/loader_spinner.gif' style="width: 50px; ">

            </v-dialog>
        </div>
        <v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                            <admin-room-menu></admin-room-menu>
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-row>
                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    주말요금 설정
                                </v-col>
                                <v-col cols="12" class="overflow-x-auto">
                                    <table class="table-top-s table-top-s-b" style="min-width: 700px;">
                                        <colgroup>
                                            <col style="width:16%"/>
                                            <col style="width:16%"/>

                                            <col style="width:16%"/>
                                            <col style="width:16%"/>

                                            <col style="width:16%"/>

                                            <!--<col style="width:4%"/>-->
                                        </colgroup>
                                        <tr>
                                            <th>금</th>
                                            <th>토</th>
                                            <th>일</th>
                                            <th>휴일전날</th>
                                            <th>휴일</th>
                                            <!--<th>수정</th>-->
                                        </tr>
                                        <tr>
                                            <td>
                                                <select v-model="holiList.friYn" style="width: 100%">
                                                    <option value="1">적용</option>
                                                    <option value="0">비적용</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select v-model="holiList.satYn" style="width: 100%">
                                                    <option value="1">적용</option>
                                                    <option value="0">비적용</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select v-model="holiList.sunYn" style="width: 100%">
                                                    <option value="1">적용</option>
                                                    <option value="0">비적용</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select v-model="holiList.eveHoliYn" style="width: 100%">
                                                    <option value="1">적용</option>
                                                    <option value="0">비적용</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select v-model="holiList.holiYn" style="width: 100%">
                                                    <option value="1">적용</option>
                                                    <option value="0">비적용</option>
                                                </select>
                                            </td>
                                        </tr>

                                    </table>
                                </v-col>
                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    성수기 설정
                                    <v-btn
                                            color="error"
                                            class="mx-2 white&#45;&#45;text center-block float-right"
                                            x-small
                                            @click="btnClick(1)"
                                    >
                                        추가
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" class="overflow-x-auto">
                                    <table class="table-top-s table-top-s-b" style="min-width: 700px;">
                                        <colgroup>
                                            <!--<col style="width:16%"/>-->
                                            <col style="width:25%"/>
                                            <col style="width:25%"/>
                                            <col style="width:25%"/>
                                            <!--<col style="width:13%"/>-->
                                            <col style="width:12%"/>
                                        </colgroup>
                                        <tr>
                                            <!--<th>번호</th>-->
                                            <th>구분</th>
                                            <th>시작일</th>
                                            <th>종료일</th>
                                            <!--<th>저장</th>-->
                                            <th>삭제</th>
                                        </tr>
                                        <tr v-for="(item, i) in peakList"
                                            :key=i
                                        >
                                            <td>
                                                <select v-model="item.peakGubun" style="width: 100%">
                                                    <option value="1">성수기</option>
                                                    <option value="2">준성수기</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div style="display: flex;">
                                                    <select v-model="item.startDateM" style="width: 45%"
                                                            @change="selectChange($event, item)">
                                                        <option v-for="(n, i) in 12" :value="n">{{n}}월</option>
                                                    </select>
                                                    <span class="mx-1">-</span>
                                                    <select v-model="item.startDateD" style="width: 45%"
                                                            @change="selectChange($event, item)">
                                                        <option v-for="(n, i) in 31" :value="n">{{n}}일</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <div style="display: flex;">
                                                    <select v-model="item.endDateM" style="width: 45%"
                                                            @change="selectChange($event, item)">
                                                        <option v-for="(n, i) in 12" :value="n">{{n}}월</option>
                                                    </select>
                                                    <span class="mx-1">-</span>
                                                    <select v-model="item.endDateD" style="width: 45%"
                                                            @change="selectChange($event, item)">
                                                        <option v-for="(n, i) in 31" :value="n">{{n}}일</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <v-btn
                                                        color="error"
                                                        class="mx-4 white--text center-block"
                                                        x-small
                                                        @click="updatePeak(item, 1)"
                                                >
                                                    삭제
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                                <v-col>

                                    <v-btn
                                            color="primary"
                                            class="mx-4 white--text center-block"
                                            @click="btnClick(0)"
                                    >
                                        저장
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>

    import AdminRoomMenu from '../../components/AdminRoomMenu'

    export default {
        name: 'AdminHoliPeakList',
        components: {
            AdminRoomMenu
        },
        data: () => ({
            page: 1,
            pageCount: 0,
            itemsPerPage: 30,

            holiList: [],
            peakList: [],
            tmpPeakId: 0,
            menuCnt: {
                total: 0,
                menu1: 0,
                menu2: 0,
                menu3: 0,
                menu4: 0,
            },
            dialog: false,

        }),
        created: function () {
            // 맨위로
            if ( this.$isMobile()) {
                window.scrollTo({top: 400, left: 0, behavior: 'smooth'});
            } else {
                window.scrollTo(0, 0);
            }

            this.getList({})
        },
        methods: {
            getList(val) {
                return this.$store.dispatch("admin/getHoliList", val)
                    .then((resp) => {
                        setTimeout(() => {
                            this.holiList = resp.message[0];

                            return this.$store.dispatch("admin/getPeakList", val)
                                .then((resp) => {
                                    setTimeout(() => {
                                        this.peakList = resp.message;
                                        this.setPeakInfo();

                                    }, 300)
                                })
                                .catch((err) => {
                                })

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            btnClick(val) {
                switch (val) {
                    case 0 : {   // 전체 저장
                        if (!confirm("전체 설정을 저장 하시겠습니까?")) {
                            // 취소(아니오) 버튼 클릭 시 이벤트
                        } else {

                            this.dialog = true;
                            let _this = this;

                            let isCheck = true;

                            this.peakList.forEach(function (e) {

                                if (e.peakStartDate.length === 0 || e.peakEndDate.length === 0) {
                                    alert('날짜를 입력해 주세요');
                                    isCheck = false;
                                    return;
                                }
                            })

                            if (isCheck) {

                                // 확인(예) 버튼 클릭 시 이벤트
                                // 주말요금 설정
                                return this.$store.dispatch("admin/updateHoli", this.holiList)
                                    .then((resp) => {
                                        setTimeout(() => {
                                            // 성수기 설정
                                            return this.$store.dispatch("admin/deletePeaks", {isDel: 'true'})
                                                .then((resp) => {
                                                    setTimeout(() => {
                                                        let index = 0;
                                                        let listIndex = _this.peakList.length;

                                                        _this.peakList.forEach(function (e) {

                                                            _this.$store.dispatch("admin/insertPeak", e)
                                                                .then((resp) => {

                                                                    index += 1;

                                                                    if (index === listIndex) {
                                                                        setTimeout(() => {
                                                                            _this.getList({});
                                                                            alert("저장했습니다.");
                                                                            _this.dialog = false;

                                                                        }, 300)
                                                                    }
                                                                })
                                                                .catch((err) => {

                                                                });
                                                        });

                                                    }, 300)
                                                })
                                                .catch((err) => {
                                                })


                                        }, 300)
                                    })
                                    .catch((err) => {
                                    });

                            } else {
                                this.dialog = false;
                            }
                        }
                        break;
                    }
                    case 1 : {   // 성수기 추가

                        let formdata = {
                            peakGubun: "1",
                            peakStartDate: "",
                            peakEndDate: "",
                            lstModPrs: "admin",
                            tmpPeakId: this.tmpPeakId,
                        };

                        this.peakList.push(formdata);

                        this.tmpPeakId += this.tmpPeakId + 1;

                        break;
                    }
                    case 2 : {   // 성수기 저장

                        break;
                    }
                }
            },
            updatePeak(val, isUpdate) {
                switch (isUpdate) {
                    case 0 : {
                        if (!confirm("성수기 설정을 저장 하시겠습니까?")) {
                            // 취소(아니오) 버튼 클릭 시 이벤트
                        } else {
                            // 확인(예) 버튼 클릭 시 이벤트
                            if (val.peakStartDate.length === 0 || val.peakEndDate.length === 0) {
                                alert('날짜를 입력해 주세요');
                                return;
                            }
                            return this.$store.dispatch("admin/updatePeak", val)
                                .then((resp) => {
                                    setTimeout(() => {
                                        this.getList({});

                                    }, 300)
                                })
                                .catch((err) => {
                                });
                        }
                        break;
                    }
                    case 1 : {
                        if (!confirm("성수기 설정 목록을 삭제 하시겠습니까?")) {
                            // 취소(아니오) 버튼 클릭 시 이벤트
                        } else {
                            // 확인(예) 버튼 클릭 시 이벤트
                            if (val.tmpPeakId != undefined) {

                                for (let i = 0; this.peakList.length > i; i++) {

                                    if (this.peakList[i].tmpPeakId === val.tmpPeakId) {
                                        this.peakList.splice(i, 1);
                                    }
                                }
                            } else {

                                for (let i = 0; this.peakList.length > i; i++) {

                                    if (this.peakList[i].peakId === val.peakId) {
                                        this.peakList.splice(i, 1);
                                    }
                                }
                            }
                        }
                        break;
                    }
                }
            },
            setPeakInfo() {

                for (let i = 0; this.peakList.length > i; i++) {

                    this.peakList[i].startDateM = Number(this.peakList[i].peakStartDate.split("-")[0]);
                    this.peakList[i].startDateD = Number(this.peakList[i].peakStartDate.split("-")[1]);

                    this.peakList[i].endDateM = Number(this.peakList[i].peakEndDate.split("-")[0]);
                    this.peakList[i].endDateD = Number(this.peakList[i].peakEndDate.split("-")[1]);

                }

            },
            selectChange(event, item) {

                item.peakStartDate = this.numberPad(item.startDateM, 2) + "-" + this.numberPad(item.startDateD, 2);
                item.peakEndDate = this.numberPad(item.endDateM, 2) + "-" + this.numberPad(item.endDateD, 2);

            },
            numberPad(n, width) {
                n = n + '';
                return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
            },

        },
        mounted() {
            this.$nextTick(() => {

            })
        },
        computed: {
            //resvConfirmInfo() { return this.$store.state.reservation.resvConfirmInfo }
        },

    }
</script>

<style>

</style>

